.App {
  text-align: center;
}

 


table th{
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  /* border: solid; */
  border-width: 1px 0px;
  border: 1px solid #ddd;

  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #3f484b;
  color: white;

  font-size: 13px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

thead > tr > th {
  background-color: #f0e8db !important;
  font-weight: bold !important;
}
 

tr:nth-child(even){background-color: #f1efef;}

tr:hover {background-color: #ddd;}

.tb1{
  font-size: 15px;
  background-color: #edf6fc;
  border: 1px solid #ead2ac;
}
  
.tb2{ 
  font-size: 15px;
  font-weight: bold;
  /* border: 1px solid black; */
  background-color: #ead2ac;
}
.tt32{ 
}